import { useState, useEffect } from 'react';

const useLocalStorage = (key, defaultValue) => {
  const initialValue = () => JSON.parse(window.localStorage.getItem(key)) || defaultValue;
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    if (Array.isArray(value) && value.length === 0) {
      window.localStorage.removeItem(key);
    } else {
      window.localStorage.setItem(key, JSON.stringify(value));
    }
  }, [value]);

  return [[...value], setValue];
};

export default useLocalStorage;
