import React, { useState, useEffect } from 'react';
import cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';
import Api from '../../helpers/api';

const Login = ({ children }) => {
  const [session, setSession] = useState(cookies.get('copybankSession'));
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
      try {
        const { username, email, groups } = jwtDecode(session);
        setCurrentUser({ username, email, groups });
      } catch (err) {
        Api('session').custom('get', {}, 'url')
          .then(response => window.location.replace(response.data))
      }
    }, [session]);

  return (
    <div>
      {currentUser && children}
    </div>
  )
}

export default Login