import React, { createContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import jwtDecode from 'jwt-decode';
import cookies from 'js-cookie'

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [session, setSession] = useState(cookies.get('copybankSession'));
  const [currentUser, setCurrentUser] = useState(null);

  const updateSession = () => {
    setSession(cookies.get('copybankSession'));
  };

  useEffect(() => {
    try {
      const { username, email, groups } = jwtDecode(session);
      setCurrentUser({ username, email, groups });
    } catch (err) {
      setCurrentUser(null);
    }
  }, [session]);

  return <AuthContext.Provider
    value={{ updateSession, currentUser }}>{children}</AuthContext.Provider>
};

AuthProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
};

export { AuthContext, AuthProvider };
