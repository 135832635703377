import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './form.styl'
import Header from '../layout/header';

const Form = ({ title, byline, status = null, actions, disabled = false, children,
                authenticationRequired = false, authenticate }) => {
  const getContentClassName = () => {
    return classNames('form__content', {
      'form__content--disabled': disabled,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  const handleEnterKeyDown = (event) => {
    if (authenticationRequired && (event?.key === 'Enter' || event?.keyCode === 13)) {
      authenticate();
    }
  }

  return (
    <form className='form' onSubmit={handleSubmit} onKeyDown={handleEnterKeyDown}>
      <div className='form__header'>
        <Header title={title} byline={byline} actions={actions} status={status}/>
      </div>
      <div className={getContentClassName()}>
        {children}
      </div>
    </form>
  );
};

Form.propTypes = {
  title: PropTypes.string,
  byline: PropTypes.string,
  status: PropTypes.object,
  actions: PropTypes.array,
  disabled: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  authenticationRequired: PropTypes.bool,
  authenticate: PropTypes.func,
};

export default Form;
