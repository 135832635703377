import React, { lazy, Suspense } from 'react';
import { hot } from 'react-hot-loader';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import Navigation from './navigation/navigation';
import { AuthProvider } from '../contexts/auth';
import { SearchProvider } from '../contexts/search';
import Login from './auth/login';

const Translations = lazy(() => import('./translations/translations'));
const SearchContainer = lazy(() => import('./search/search_container'));
const Export = lazy(() => import('./export/export'));
const Settings = lazy(() => import('./settings/settings'));
const EditTextContainer = lazy(() => import('./text/edit_text_container'));
const Import = lazy(() => import('./import/import'));
const Orders = lazy(() => import('./orders/orders'));
const OrdersNew = lazy(() => import('./orders/new'));
const OrdersEdit = lazy(() => import('./orders/edit'));
const OrdersShow = lazy(() => import('./orders/show'));
const OrdersImport = lazy(() => import('./orders/import'));

const LoadingMessage = () => (
  <div className='container'>
    <div className='loading'>
    </div>
  </div>
);

const App = () => {
  return (
    <Login>
      <BrowserRouter>
        <AuthProvider>
          <SearchProvider>
            <div className='app'>
              <Navigation/>
              <Suspense fallback={<LoadingMessage/>}>
                <Switch>
                  <Route exact path='/' component={SearchContainer}/>
                  <Route path='/translations' component={Translations}/>
                  <Route path='/export' component={Export}/>
                  <Route path='/settings' component={Settings}/>
                  <Route path='/import' component={Import}/>
                  <Route exact path='/orders' component={Orders}/>
                  <Route exact path='/orders/new' component={OrdersNew}/>
                  <Route exact path='/orders/import/:year/:week' component={OrdersImport}/>
                  <Route exact path='/orders/:id' component={OrdersShow}/>
                  <Route exact path='/orders/:id/edit' component={OrdersEdit}/>
                  <Route path='/texts/:id/edit' component={EditTextContainer}/>
                  <Route path='/texts/new' component={EditTextContainer}/>
                </Switch>
              </Suspense>
            </div>
          </SearchProvider>
        </AuthProvider>
      </BrowserRouter>
    </Login>
  );
};

export default hot(module)(App);
