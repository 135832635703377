import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './title.styl';

const Title = ({ title, badge, modifiers = [] }) => {
  const getClassName = () => {
    return classNames('title', modifiers.map(modifier => `title--${modifier}`));
  };

  return (
    <div className={getClassName()}>
      <div className='title__title'>{title}</div>
      {badge ? <div className='title__badge'>{badge}</div> : null}
    </div>
  )
};

Title.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
  ]),
  badge: PropTypes.string,
  modifiers: PropTypes.array,
};

export default Title;
