import axios from 'axios';

const apiPath = '/api';

const getCustomUrl = (resource, actions) => {
  let url = `${apiPath}/${resource}`;

  if (actions.length > 0) {
    url += `/${actions.join('/')}`;
  }

  return url;
};

const getCustomConfig = (resource, method, params, actions) => {
  const config = { method, url: getCustomUrl(resource, actions) };

  if (method.toLowerCase() === 'get') {
    config.params = params;
  } else {
    config.data = params;
  }

  return config;
};

const Api = (resource) => {
  return {
    file: (params) => axios.get(`${apiPath}/${resource}`, { responseType: 'blob', params }),
    getOne: (id) => axios.get(`${apiPath}/${resource}/${id}`),
    getAll: (params) => axios.get(`${apiPath}/${resource}`, { params }),
    update: (id, params) => axios.put(`${apiPath}/${resource}/${id}`, params),
    create: (params) => axios.post(`${apiPath}/${resource}`, params),
    delete: (id, params) => axios.delete(`${apiPath}/${resource}/${id}`, { data: { ...params } }),
    restore: (id) => axios.put(`${apiPath}/${resource}/${id}/restore`, ),
    custom: (method, params, ...actions) => axios(getCustomConfig(resource, method, params, actions)),
  };
};

export default Api;
