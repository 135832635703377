import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './header.styl';

import Title from './title';

const Header = ({ title, badge, status, actions, modifiers = [] }) => {
  const getClassName = () => {
    return classNames('header', modifiers.map(modifier => `header--${modifier}`));
  };

  const getStatusClassName = () => {
    return classNames('header__status', `header__status--${status?.type ?? 'normal'}`, modifiers.map(modifier => `header--${modifier}`));
  };

  return (
    <div className={getClassName()}>
      <Title title={title} badge={badge} modifiers={modifiers}/>
      <div className={getStatusClassName()}>{status?.message}</div>
      <div className='header__actions'>{actions}</div>
    </div>
  )
};

Header.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
  ]),
  badge: PropTypes.string,
  status: PropTypes.object,
  actions: PropTypes.array,
  modifiers: PropTypes.array,
};

export default Header;
