import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import classNames from 'classnames';

import './button.styl'

const Button = ({ disabled, onClick, modifiers = [], title, value, icon, text, id, to, history }) => {
  const getClassName = () => {
    return classNames('button', modifiers.map(modifier => `button--${modifier}`));
  };

  const handleClick = (event) => {
    if (to) {
      history.push(to);
    } else {
      onClick(event);
    }
  };

  return (
    <button className={getClassName()} disabled={disabled} onClick={handleClick} title={title} id={id} value={value}
            type={'button'}>
      {icon ? <span className='button__icon'>{icon}</span> : null}
      <span className='button__text'>{text}</span>
    </button>
  );
};

Button.propTypes = {
  text: PropTypes.string,
  disabled: PropTypes.bool,
  modifiers: PropTypes.array,
  onClick: PropTypes.func,
  title: PropTypes.string,
  value: PropTypes.string,
  icon: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  to: PropTypes.string,
  id: PropTypes.string,
  history: PropTypes.object,
};

export default withRouter(Button);
