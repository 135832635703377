import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './modal.styl'

const Modal = ({ handleClose, children, open, fixed = false }) => {
  const handleClick = (event) => {
    if (event.target === event.currentTarget) {
      handleClose();
    }
  };

  const getClassName = () => {
    return classNames('modal', {
      'modal--open': open,
      'modal--fixed': fixed,
    });
  };

  return (
    <div onClick={handleClick} className={getClassName()}>
      <div className='modal__inner'>
        {children}
      </div>
    </div>
  );
};

Modal.propTypes = {
  handleClose: PropTypes.func,
  open: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  fixed: PropTypes.bool,
};

export default Modal;
