import React, { useContext, useEffect } from 'react';

import Api from '../../helpers/api';
import { AuthContext } from '../../contexts/auth';

import Icon from '../../assets/icon-user.svg';

import './auth.styl'

const Auth = () => {
  const { currentUser, updateSession } = useContext(AuthContext);

  const logOut = () => {
    Api('sessions').custom('delete')
      .then(response => {
        updateSession();
        window.location.replace(response.data);
      });
  };

  useEffect(() => {
    updateSession();
  }, []);

  const getNavItem = () => {
    if (currentUser) {
      return (
        <div className='navigation__item' onClick={logOut}>
          <Icon width={20} height={20} className='navigation__icon'/>Log out
        </div>
      )
    }
  };

  return (
    <div className='auth'>
      {getNavItem()}
    </div>
  )
};

export default Auth;
