import React, { useContext } from 'react';

import Auth from '../auth/auth';
import Export from '../export/export';
import { AuthContext } from '../../contexts/auth';
import NavigationItem from './navigationItem';

import Logo from '../../assets/hm-logo.svg';

import './navigation.styl';
import authHelper from '../../helpers/authHelper';

const Navigation = () => {
  const { currentUser } = useContext(AuthContext);

  const getNavigationItems = () => {
    const items = [];
    const { groups } = authHelper;

    if (authHelper.isAllowed(currentUser, groups.SUPER_USER, groups.COPY_WRITER, groups.PRODUCTION_FULFILLMENT,
      groups.GLOBAL_VM, groups.VM)) {
      items.push(<NavigationItem path={'/translations'} key={'translations'}>Translations</NavigationItem>);
    }
    if (authHelper.isAllowed(currentUser, groups.SUPER_USER, groups.PRODUCTION_FULFILLMENT)) {
      items.push(<Export key={'export'}/>);
      items.push(<NavigationItem path={'/import'} key={'import'}>Import</NavigationItem>);
      items.push(<NavigationItem path={'/texts/new'} key={'addText'}>Add text</NavigationItem>);
    }
    if (authHelper.isAllowed(currentUser, groups.SUPER_USER, groups.VM, groups.COPY_WRITER, groups.GLOBAL_VM,
      groups.PRODUCTION_FULFILLMENT)) {
      items.push(<NavigationItem path={'/orders'} key={'orders'}>Orders</NavigationItem>);
    }
    if (currentUser) {
      items.push(<NavigationItem path={'/settings'} key={'settings'}>Settings</NavigationItem>);
    }

    items.push(<Auth key={'auth'}/>);

    return items;
  };

  return (
    <div className='container container--red-dark'>
      <nav className='navigation'>
        <a href='/' className='navigation__brand'>
          <Logo width={35} height={35} fill={'transparent'} className='navigation__logo'/>
          Copybank
        </a>
        <div className='navigation__main'>
          {getNavigationItems()}
        </div>
      </nav>
    </div>
  );
};

export default Navigation;
