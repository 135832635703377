const handleError = (error, setStatus) => {
  if (error.response.status === 422) {
    setStatus({
      message: 'Validation failed',
      type: 'error',
      errors: error.response.data.errors,
    });
  } else if (error.response.status === 401) {
    setStatus({
      message: 'Not logged in — please login and try again',
      type: 'error'
    });
  } else {
    setStatus({ type: 'error', message: 'Something went wrong' });
  }
};

export default {
  handleError,
}
