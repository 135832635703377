import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

import './navigationItem.styl';

const NavigationItem = ({ children, path }) => {
  return <NavLink className='navigation__item' activeClassName={'navigation__item--active'}
                  to={path}>{children}</NavLink>;
};

NavigationItem.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  path: PropTypes.string,
};

export default NavigationItem;
