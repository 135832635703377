import React, { useState, useContext } from 'react';

import './export.styl'
import Form from '../form/form';
import Api from '../../helpers/api';
import Button from '../form/button';
import Modal from '../modal/modal';
import { SearchContext } from '../../contexts/search';
import errorHelper from '../../helpers/errorHelper';

const Export = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState(null);
  const { parsedQuery, favorites } = useContext(SearchContext);

  const showModal = () => setModalOpen(true);

  const hideModal = () => setModalOpen(false);

  const handleExportResults = () => {
    setIsLoading(true);
    fetchFile({ ...parsedQuery });
  };

  const handleExportFavorites = () => {
    setIsLoading(true);
    fetchFile({ ids: favorites.map(favorite => favorite.id) });
  };

  const fetchFile = (params) => {
    Api('export').file(params)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        const timestamp = (new Date).toISOString().replace(/\..*/, '').replace(/[:-]/g, '_');
        link.href = url;
        link.setAttribute('download', `export-${timestamp}.xlsx`);
        document.body.appendChild(link);
        link.click();
        setStatus(null);
      })
      .catch((error) => errorHelper.handleError(error, setStatus))
      .finally(() => setIsLoading(false))
  };

  const getActions = () => {
    const actions = [<Button key={'results'} onClick={handleExportResults} text='Results' disabled={isLoading}/>];

    if (favorites.length > 0) {
      actions.push(<Button key={'favorites'} onClick={handleExportFavorites} text='Favorites' disabled={isLoading}/>)
    }

    return actions;
  };

  return (
    <div className='export'>
      <div className='navigation__item' onClick={showModal}>Export</div>
      <Modal handleClose={hideModal} open={modalOpen}>
        <Form title={'Export'} status={status} actions={getActions()}>
        </Form>
      </Modal>
    </div>
  )
};

export default Export;
