import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';

import useLocalStorage from '../helpers/localStorage';

const SearchContext = createContext();

const getQueryState = () => {
  const queryState = queryString.parse(location.search, { arrayFormat: 'bracket' });

  return {
    query: queryState.query || '',
    locale: queryState.locale,
    fields: queryState.fields?.map(field => Number(field)),
    presets: queryState.presets?.map(preset => Number(preset)),
    channels: queryState.channels?.map(channel => Number(channel)),
    tags: queryState.tags?.map(tag => Number(tag)),
    orders: queryState.orders?.map(order => Number(order)),
  }
};

const useFilter = (initialState = {}) => {
  const [all, setAll] = useState(initialState.all || []);
  const [selected, setSelected] = useState(initialState.selected || []);
  const [available, setAvailable] = useState(initialState.available || []);

  return { all, setAll, selected, setSelected, available, setAvailable };
};

const queryState = getQueryState();

const SearchProvider = ({ children }) => {
  const [query, setQuery] = useState(queryState.query);
  const [locale, setLocale] = useState(queryState.locale);
  const [pins, setPins] = useLocalStorage('pins', []);
  const [favorites, setFavorites] = useLocalStorage('favorites', []);
  const tags = useFilter({ selected: queryState.tags });
  const orders = useFilter({ selected: queryState.orders });
  const fields = useFilter({ selected: queryState.fields });
  const presets = useFilter({ selected: queryState.presets });
  const channels = useFilter({ selected: queryState.channels });
  const parsedQuery = {
    query,
    locale,
    tags: tags.selected,
    orders: orders.selected,
    fields: fields.selected,
    presets: presets.selected,
    channels: channels.selected
  };

  return (
    <SearchContext.Provider value={{
      query,
      setQuery,
      pins,
      setPins,
      locale,
      setLocale,
      favorites,
      setFavorites,
      tags,
      orders,
      fields,
      presets,
      channels,
      parsedQuery,
    }}>{children}</SearchContext.Provider>
  )
};

SearchProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
};

export { SearchContext, SearchProvider };
