const SUPER_USER = 'copybank-super-user';
const VM = 'copybank-vm';
const COPY_WRITER = 'copybank-copy-writer';
const PRODUCTION_FULFILLMENT = 'copybank-production-fulfillment';
const GLOBAL_VM = 'copybank-global-vm';

const isAllowed = (currentUser, ...groups) => {
  return currentUser && currentUser.groups.some(group => groups.includes(group));
};

export default {
  isAllowed,
  groups: {
    SUPER_USER,
    VM,
    COPY_WRITER,
    PRODUCTION_FULFILLMENT,
    GLOBAL_VM,
  }
}
